<template>
  <div>
    <div class="main-container">
      <p class="rem36 c-333333 f500 apply-address">请检查您的身份信息</p>
      <div class="text-center id-upload">
        <div :class="!uploadFront ? 'uploadFront' : ''">
          <van-uploader :after-read="compressFile" v-if="!uploadFront">
            <img :src="backPng" alt="" />
            <p class="rem26 c-4D7BFE">上传身份证正面</p>
          </van-uploader>
          <van-uploader v-else :multiple="false" :after-read="compressFile">
            <img :src="frontFile.content" alt="" class="idCardImg" />
          </van-uploader>
        </div>
        <div class="mt24" :class="!uploadReverse ? 'uploadFront' : ''">
          <van-uploader :after-read="compressFile1" v-if="!uploadReverse">
            <img :src="frontPng" alt="" />
            <p class="rem26 c-4D7BFE">上传身份证国徽面</p>
          </van-uploader>
          <van-uploader v-else :multiple="false" :after-read="compressFile1">
            <img :src="reverseFile.content" alt="" class="idCardImg" />
          </van-uploader>
        </div>
      </div>
      <p class="id-shoot rem30 text-center mt24">拍摄示例</p>
    </div>
    <div class="flex just-center align-center">
      <div v-for="(item, index) in examList" :key="index" class="text-center mr4">
        <p class="exam"><img :src="item.image" alt="" /></p>
        <p class="typeImage"><img :src="item.typeImage" class="img16" alt="" /></p>
        <p>{{ item.name }}</p>
      </div>
    </div>
    <div class="mt20 p-l-r20">
      <van-button
        round
        block
        type="info"
        @click="nextStep"
        :disabled="!uploadFront || !uploadReverse"
        >下一步</van-button
      >
    </div>
    <!-- <van-uploader :after-read="afterRead" /> -->
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button, Uploader, Toast } from 'vant'

import { OcrIdCardApi } from '@/apis/credit'
import { uploadFileNewApi, OcrIdCardNewApi, OcrIdCardUpdateApi } from '@/apis/userCenter'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)
Vue.use(Uploader)

export default {
  data() {
    return {
      value: '',
      frontPng: require('@/assets/images/credit/idCard-front.png'),
      backPng: require('@/assets/images/credit/idCard-back.png'),
      standardPng: require('@/assets/images/credit/standard-shoot.png'),
      err1Png: require('@/assets/images/credit/upload-err1.png'),
      err2Png: require('@/assets/images/credit/upload-err2.png'),
      err3Png: require('@/assets/images/credit/upload-err3.png'),
      examList: [
        {
          name: '标准拍摄',
          image: require('@/assets/images/credit/standard-shoot.png'),
          typeImage: require('@/assets/images/credit/success.png'),
        },
        {
          name: '边框缺失',
          image: require('@/assets/images/credit/upload-err1.png'),
          typeImage: require('@/assets/images/credit/error.png'),
        },
        {
          name: '照片模糊',
          image: require('@/assets/images/credit/upload-err2.png'),
          typeImage: require('@/assets/images/credit/error.png'),
        },
        {
          name: '闪光强烈',
          image: require('@/assets/images/credit/upload-err3.png'),
          typeImage: require('@/assets/images/credit/error.png'),
        },
      ],
      frontFile: {},
      reverseFile: {},
      uploadFront: false,
      uploadReverse: false,
      type: 'add',
      isSubmit: false,
      submitFile: {
        frontImgId: '',
        reverseImgId: '',
      },
    }
  },
  created() {
    // eslint-disable-next-line no-multi-assign
    this.uploadFront = this.uploadReverse = false
    this.type = this.$route.query.type ? this.$route.query.type : 'add'
  },
  computed: {},
  methods: {
    photoCompress(file, w, objDiv) {
      let that = this
      var ready = new FileReader()
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file.file)
      ready.onload = function () {
        var re = that.result
        that.canvasDataURL(file.content, w, objDiv)
      }
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    canvasDataURL(path, obj, callback) {
      var img = new Image()
      img.src = path
      img.onload = function () {
        var that = this
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        var quality = 0.2 // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        // 创建属性节点
        var anw = document.createAttribute('width')
        anw.nodeValue = w
        var anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    compressFile(file) {
      const loadingToast = Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
      })
      let that = this
      that.frontFile = file
      var fileSize = parseFloat(parseInt(file.file.size) / 1024 / 1024).toFixed(2)
      if (Number(fileSize) > 10) {
        that.uploadFront = false
        that.submitFile.frontImgId = ''
        this.$common.toast('上传图片太大，请重新拍照上传。')
        return
      }
      if (Number(fileSize) > 2 && file.file.type.indexOf('image') != '-1') {
        that.photoCompress(
          file,
          {
            quality: 0.2,
          },
          function (base64Codes) {
            //console.log("压缩后：" + base.length / 1024 + " " + base);
            var bl = that.convertBase64UrlToBlob(base64Codes)
            let newFile = new File([bl], file.file.name, { type: file.file.type.toLowerCase() })
            that.frontFile.file = newFile
            const formdata = new FormData()
            formdata.append('file', that.frontFile.file)
            const query = {
              fileSeqNo: '1',
              fileTypeCode: '1', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
            }

            uploadFileNewApi(query, formdata)
              .then((res) => {
                if (res.code === 200) {
                  that.submitFile.frontImgId = res.data
                  that.uploadFront = true
                  loadingToast.clear()
                } else {
                  loadingToast.clear()
                }
              })
              .catch(() => {
                loadingToast.clear()
              })
          }
        )
      } else {
        const formdata = new FormData()
        formdata.append('file', that.frontFile.file)
        const query = {
          fileSeqNo: '1',
          fileTypeCode: '1', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
        }
        uploadFileNewApi(query, formdata)
          .then((res) => {
            if (res.code === 200) {
              that.submitFile.frontImgId = res.data
              that.uploadFront = true
              loadingToast.clear()
            } else {
              loadingToast.clear()
            }
          })
          .catch(() => {
            loadingToast.clear()
          })
      }
    },
    compressFile1(file) {
      const loadingToast = Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
      })
      let that = this
      that.reverseFile = file
      var fileSize = parseFloat(parseInt(file.file.size) / 1024 / 1024).toFixed(2)
      if (Number(fileSize) > 10) {
        that.uploadReverse = false
        that.submitFile.reverseImgId = ''
        that.$common.toast('上传图片太大，请重新拍照上传。')
        return
      }
      if (Number(fileSize) > 2 && file.file.type.indexOf('image') != '-1') {
        console.log(123)
        that.photoCompress(
          file,
          {
            quality: 0.2,
          },
          function (base64Codes) {
            //console.log("压缩后：" + base.length / 1024 + " " + base);
            var bl = that.convertBase64UrlToBlob(base64Codes)
            let newFile = new File([bl], file.file.name, { type: file.file.type.toLowerCase() })
            that.reverseFile.file = newFile
            const formdata = new FormData()
            formdata.append('file', that.reverseFile.file)

            const query = {
              fileSeqNo: '2',
              fileTypeCode: '4',
            }
            uploadFileNewApi(query, formdata)
              .then((res) => {
                if (res.code === 200) {
                  that.submitFile.reverseImgId = res.data
                  that.uploadReverse = true
                  loadingToast.clear()
                } else {
                  loadingToast.clear()
                }
              })
              .catch(() => {
                loadingToast.clear()
              })
          }
        )
      } else {
        const formdata = new FormData()
        formdata.append('file', that.reverseFile.file)
        const query = {
          fileSeqNo: '2',
          fileTypeCode: '4',
        }
        uploadFileNewApi(query, formdata)
          .then((res) => {
            if (res.code === 200) {
              that.submitFile.reverseImgId = res.data
              that.uploadReverse = true
              loadingToast.clear()
            } else {
              loadingToast.clear()
            }
          })
          .catch(() => {
            loadingToast.clear()
          })
      }
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    nextStep() {
      this.showloading()
      if (this.type === 'add') {
        OcrIdCardNewApi(this.submitFile)
          .then((res) => {
            if (res.code === 200) {
              this.$router.push({
                path: '/check-info',
                query: {
                  userInfo: JSON.stringify(res.data),
                },
              })
              this.hideloading()
            }
          })
          .catch(() => {
            this.hideloading()
          })
      } else {
        OcrIdCardUpdateApi(this.submitFile)
          .then((res) => {
            this.$router.push({
              path: '/idCardInfo',
              query: {
                userInfo: JSON.stringify(res.data),
              },
            })
            this.hideloading()
          })
          .catch(() => {
            this.hideloading()
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding-bottom: 0.12rem;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 0.46rem;
}
.apply-address {
  margin-bottom: 0.8rem;
}
.id-upload {
  padding: 0 0.56rem;
  .uploadFront {
    background-color: #eaf1fa;
    padding: 0.26rem 0.96rem 0.2rem;
    box-sizing: border-box;
    img {
      width: 3.68rem;
      height: 2.26rem;
    }
  }
}
.id-shoot {
  color: #394148;
}
.exam {
  img {
    width: 1.7rem;
    height: 1.18rem;
  }
}
.typeImage {
  margin-top: -0.2rem;
}
.uploader {
  position: relative;
  .inputFile {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 3.12rem;
    width: 5.6rem;
  }
}
.idCardImg {
  width: 5.58rem !important;
  height: 3rem !important;
}
</style>
